export const URL_DASHBOARD = '/dashboard';
export const URL_USER = '/users';
export const URL_ADMIN = '/admins';
export const URL_LOGIN = '/login';
export const URL_STAFF_MEMBERS = '/staff-members';
export const URL_SETTINGS = '/settings';
export const URL_PAGES = '/pages';
export const URL_CANDIDATES = '/candidates';
export const URL_EMPLOYEES = '/employees';
export const URL_TALENT_INSIGHTS = '/talent-insights';
export const URL_CREATE_CANDIDATE = '/create-candidate';
export const URL_EDIT_CANDIDATE = '/edit-candidate/:id';
export const URL_REPORTS = '/reports';
export const URL_ONA = '/ona';
export const URL_JOBS = '/jobs';
export const URL_THEMEOPTIONS = '/theme-options';
export const URL_ASSESSMENTS = '/assessments';
export const URL_ASSESSMENT_DETAILS = '/assessments/:id';
export const URL_ONGOING_ASSESSMENTS_LIST = '/ongoing-assessments-list';
export const URL_COMPLETED_ASSESSMENTS_LIST = '/completed-assessments-list';
export const URL_ARCHIVED_ASSESSMENTS_LIST = '/archived-assessments-list';
export const URL_TERMS = '/terms';
export const URL_PRIVACY = '/privacy';
export const URL_REPORTS_DETAILS = '/reports/:id?';
export const URL_CREATE_JOB = '/create-job';
export const URL_EDIT_JOB = '/edit-job/:id';
export const URL_CREATE_ASSESSMENTS = '/create-assessment';
export const URL_EDIT_ASSESSMENTS = '/edit-assessment/:id';

export const TRAITSNAME = [
  'OPENED',
  'STARTED',
  'COMPLETED',
  'CANCELED',
  'ARCHIVED',
];

export const TYPE_IDS = {
  PERSONALITY: 1,
  SITUATIONAL: 2,
  CAREER_DRIVERS: 3,
  VIDEO: 4,
  PROFILE_QUESTION: 5,
  GAMES: 6,
  TECHNICAL: 7,
  SURVEY: 8,
  WHITE_BOX_VIDEO: 9,
} as const;

export const CATEGORIES = {
  PERSONALITY: 1,
  SJT: 2,
  DRIVERS: 3,
  MISC: 5,
  GAMES: 6,
  SWIPE_GAMES: 7,
  SHAPE_GAMES: 8,
  ROTATE_GAMES: 9,
  MATCHED_GAMES: 10,
  WHITE_BOX_VIDEO: 11,
} as const;

export const CATEGORY_REDUX_KEYS = {
  1: 'personalityData',
  2: 'sjtData',
  3: 'careerDriversData',
  4: 'videoData',
  5: 'profileQueData',
  6: 'gamesData',
  7: 'technicalQueData',
  8: 'surveyQueData',
  9: 'videoData',
};

export const API_START_POINT = process.env.REACT_APP_API_URL;
export const API_PATH = {
  URL_LOGIN_DETAILS: 'auth/token/login/',
  URL_AZURE_LOGIN_DETAILS: 'auth/token/microsoft/',
  URL_KNOKARI_LIST:
    'https://stg-api.knockri.com/services/v1/applicants/results',
  URL_CANDIDATE: 'auth/candidates/',
  URL_CANDIDATE_LIST: 'auth/candidates_list/',
  URL_CUSTOM_TEAM_FIT: 'auth/candidates_list/team_fit/team_fit/',
  URL_CUSTOM_TEAM_FIT_DROP_DOWN: 'auth/candidates_list/team_fit/dropdown/',
  URL_MULTI_JOB_FIT: 'v2/insights/get_multiple_fit_score/',
  URL_MULTI_JOB_FIT_EXPORT: 'v2/insights/export_data/',
  URL_TRAITS_LIST: 'auth/candidate-traits/',
  URL_USER_DETAILS: API_START_POINT + 'auth/users/me/', // FOR USER LOGIN AFTER DETAILS
  URL_USER_EDIT_DETAILS: 'auth/users/me/',
  URL_CHANGE_PASSWORD: 'auth/users/set_password/',
  URL_USER_LOGOUT: 'auth/token/logout/',
  URL_USER_PROFILE_UPDATE: 'auth/users/me/',
  URL_APPLICATIONS: 'v1/applications/',
  URL_APPLICATIONS_EXTEND: 'v1/applications/actions/extend',
  URL_VIDEO_REPORT: 'v1/assessments/',
  VIDEO_DATA: 'v1/assessments/',
  CANDIDATE_DETAILS: 'auth/candidates/',
  DOWNLOAD_CANDIDATE_REPORT: `${process.env.REACT_APP_PORTAL_URL}/assessment/`,
  DOWNLOAD_MANAGER_REPORT: `${process.env.REACT_APP_PORTAL_URL}/application/`,
  DOWNLOAD_ADV_MANAGER_REPORT: `${process.env.REACT_APP_PORTAL_URL}/application/`,
  DOWNLOAD_ADV_MANAGER_REPORT2: API_START_POINT + 'report',
  DOWNLOAD_ADV_MANAGER_REPORTS: API_START_POINT + 'reports',
  URL_USER_SUPPORT: API_START_POINT + 'auth/contact/',
  COMPANY_DETAIL: 'v1/company-ref/',
  URL_API_DASHBOARD: 'auth/dashboard/',
  URL_API_APPLICATION: 'auth/application-chart/',
  URL_API_JOBS: 'v2/job_api/job_list/',
  URL_JOBS: 'v2/job_api/',
  URL_LIGHT_JOBS: 'v2/job_api/light/',
  URL_ASSESSMENTS_LIST: 'v2/tests/test_basic/?company_id=',
  URL_ASSESSMENTS_TESTS: 'v2/tests/assessment_test_basic/',
  URL_ASSESSMENT_TESTS_LIST: 'v2/questions/question_library_basic/?type=',
  URL_BULK_CREATE_ASSESSMENT_TESTS: 'v2/tests/bulk_create/',
  URL_ASSESSMENT_LIST_DRPDOWN: 'v1/assessment_stages/profiles/?company=',
  URL_ASSESSMENT_LIGHT_LIST_DRPDOWN:
    'v1/assessment_stages/profiles/light/?company=',
  URL_ASSESSMENT_LIGHT_LIST:
    'v1/assessment_stages/profiles/assessment_list/?company=',
  URL_SAVE_ASSESSMENT: 'v2/assessment_template/finalize_template/',
  URL_ASSESSMENT_LISTING: 'v2/assessment_stages/profiles/?company=',
  URL_ASSESSMENT_RETRIEVE: (id: number) =>
    `v2/assessment_stages/profiles/${id}`,
  URL_ADD_QUESTION: 'v2/question_handle/add_question_library/',
  URL_DELETE_QUESTION: 'v2/question_handle/',
  WHITEBOX_VIDEO: 'v1/whitebox_video/',
  URL_ADD_ASSESSMENT_STAGES_PROFILE: 'v2/assessment_stages/profiles/',
  URL_BULK_CREATE_ASSESSMENT_STAGES: 'v2/assessment_stages/stages/bulk_create/',
  URL_BULK_UPDATE_ASSESSMENT_STAGES: 'v2/assessment_stages/stages/bulk_update/',
  URL_COMPANY_DEPARTMENTS: 'company/departments',
  URL_CHATBOT_INIT: 'v2/chatbot/initialise',
  URL_CHATBOT: 'v2/chatbot/prompt',
  URL_EXPORT_JOB_DATA: 'v2/jobs/export_job_data/',
  URL_DOWNLOAD_PERFORMANCE_DATA:
    'auth/candidates/export_candidate_performance_data/',
  URL_UPLOAD_PERFORMANCE_DATA:
    'auth/candidates/import_candidate_performance_data/',
};

export const USER_DATA = {
  GENDER: [
    { ar: '', en: '' },
    { ar: 'ذكر', en: 'Male' },
    { ar: 'أنثى', en: 'Female' },
    { ar: 'آخر', en: 'Other' },
  ],
  ETHNICITY: [
    '',
    'White',
    'Mixed',
    'Asian',
    'Arab',
    'Emirati',
    'Black',
    'Other',
  ],
  ENGLISH_LEVEL: [
    { ar: '', en: '' },
    { en: 'Beginner', ar: 'مبتدئ' },
    { en: 'Intermediate', ar: 'متوسط' },
    { en: 'Advanced/Native', ar: 'متقدم/اللغة الأم' },
  ],
  EDUCATION: [
    { ar: '', en: '' },
    { ar: 'لا يوجد', en: 'No Formal Education' },
    { ar: 'تعليم أساسي', en: 'Primary Education' },
    { ar: 'تعليم ثانوي', en: 'Secondary Education' },
    { ar: 'درجة البكالوريوس', en: "Bachelor's Degree" },
    { ar: 'درجة الماجستير', en: "Master's Degree" },
    { ar: 'دكتوراه أو أعلى', en: 'Doctorate or Higher' },
  ],

  PRIMARY_JOB_FUNCTION: [
    { ar: '', en: '' },
    { ar: 'التحليلات وعلوم البيانات', en: 'Analytics and Data Science' },
    { ar: 'الخدمات المصرفية والتأمين', en: 'Banking and Insurance' },
    { ar: 'الاتصالات', en: 'Communications' },
    { ar: 'خدمة العملاء/مركز الاتصال', en: 'Customer Service/Call Center' },
    { ar: 'التسويق الرقمي', en: 'Digital Marketing' },
    { ar: 'التعليم والتدريب', en: 'Education and Training' },
    { ar: 'الهندسة', en: 'Engineering' },
    { ar: 'الإدارة التنفيذية/العامة', en: 'Executive/General Management' },
    { ar: 'المالية والمحاسبة', en: 'Finance and Accounting' },
    { ar: 'الدعم العام والإداري', en: 'General and Adminstrative Support' },
    {
      ar: 'علوم الأرض والزراعة والغابات',
      en: 'Geoscience, Agriculture and Forestry',
    },
    { ar: 'الرعاية الصحية', en: 'Health Care' },
    { ar: 'الضيافة والسياحة', en: 'Hospitality and Tourism' },
    { ar: 'الموارد البشرية', en: 'Human Resources' },
    { ar: 'تكنولوجيا المعلومات', en: 'Information Technology' },
    { ar: 'القانونية والامتثال', en: 'Legal and Compliance' },
    { ar: 'الخدمات اللوجستية/سلسلة التوريد', en: 'Logistics/Supply Chain' },
    { ar: 'التسويق', en: 'Marketing' },
    { ar: 'العمليات', en: 'Operations' },
    { ar: 'إدارة المشاريع والبرامج', en: 'Project and Program Management' },
    {
      ar: 'إدارة العقارات والإنشاءات',
      en: 'Property Management and Construction',
    },
    { ar: 'السلامة العامة والعسكرية', en: 'Public Saftey and Military' },
    { ar: 'ضمان الجودة', en: 'Quality Assurance' },
    { ar: 'البحث والتطوير', en: 'Research and Development' },
    { ar: 'تجارة التجزئة', en: 'Retail' },
    { ar: 'إدارة المخاطر', en: 'Risk Management' },
    { ar: 'السلامة والصحة والبيئة', en: 'Saftey, Health, and Environment' },
    { ar: 'المبيعات', en: 'Sales' },
    {
      ar: 'وضع وتنفيذ الاستراتيجية والسياسات',
      en: 'Strategy and Policy Creation and Implementation',
    },
    { ar: 'التداول', en: 'Trading' },
    { ar: 'عاطل عن العمل', en: 'Unemployed' },
  ],
  JOB_LEVEL: [
    { ar: '', en: '' },
    { ar: 'مستوى مبتدئ', en: 'Entry Level' },
    { ar: 'مساهم فردي', en: 'Individual Contributor' },
    { ar: 'مساهم فردي أول', en: 'Senior Individual Contributor' },
    { ar: 'مدير', en: 'Manager' },
    { ar: 'مدير المدراء', en: 'Manager of Managers' },
    { ar: 'قائد أول', en: 'Senior Leader' },
    { ar: 'مسؤول تنفيذي', en: 'C-Suite' },
    { ar: 'الرئيس التنفيذي', en: 'CEO' },
  ],
};
